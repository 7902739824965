import React from "react"
// Ignore missing
import Bag from "../assets/icons/Bag.svg"
import Calendar from "../assets/icons/Calendar.svg"
import Coffee from "../assets/icons/Coffee.svg"
import Couple from "../assets/icons/Couple.svg"
import Family from "../assets/icons/Family.svg"
import Filter from "../assets/icons/Filter.svg"
import Group from "../assets/icons/Group.svg"
import Heart from "../assets/icons/Heart.svg"
import Home from "../assets/icons/Home.svg"
import InfoSquare from "../assets/icons/Info-square.svg"
import LocationPin from "../assets/icons/Location.svg"
import Profile from "../assets/icons/Profile.svg"
import Restaurant from "../assets/icons/Restaurant.svg"
import Sailboat from "../assets/icons/Sailboat.svg"
import Search from "../assets/icons/Search.svg"
import Star from "../assets/icons/Star.svg"
import Clock from "../assets/icons/Time-circle.svg"
import Tree from "../assets/icons/Tree.svg"
import Work from "../assets/icons/Work.svg"

const map: { [name: string]: any } = {
  Talo: (className: string) => <Home className={className} />,
  Tähti: (className: string) => <Star className={className} />,
  Kuusi: (className: string) => <Tree className={className} />,
  Purjevene: (className: string) => <Sailboat className={className} />,
  Ostoskassi: (className: string) => <Bag className={className} />,
  Sydän: (className: string) => <Heart className={className} />,
  "Yksi ihminen": (className: string) => <Profile className={className} />,
  "Kaksi ihmistä": (className: string) => <Couple className={className} />,
  "Useita ihmisiä": (className: string) => <Group className={className} />,
  Perhe: (className: string) => <Family className={className} />,
  Salkku: (className: string) => <Work className={className} />,
  Kalenteri: (className: string) => <Calendar className={className} />,
  Suodatin: (className: string) => <Filter className={className} />,
  Info: (className: string) => <InfoSquare className={className} />,
  Sijaintineula: (className: string) => <LocationPin className={className} />,
  Suurennuslasi: (className: string) => <Search className={className} />,
  Kello: (className: string) => <Clock className={className} />,
  Kahvikuppi: (className: string) => <Coffee className={className} />,
  "Haarukka ja lusikka": (className: string) => (
    <Restaurant className={className} />
  ),
}

export default function IconForName(props: {
  name: string
  className?: string
}) {
  return (
    map[props.name](props.className) || <Star className={props.className} />
  )
}
